import React from 'react';
import * as Styled from './slpFFFooterStyles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import useContentfulAssets from '../../hooks/useContentfulAssets';

export default function slpFFFooter({ sectionData }) {
  const optionsHeaderMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        return asset?.node?.file?.url?.includes('image') ? (
          <Styled.TitleDesktopImage
            src={asset?.node?.file?.url}
            alt={asset?.node?.title}
          />
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <Styled.FootContainer>
      <Styled.Foot>
        {sectionData?.contentDetails?.raw &&
          documentToReactComponents(
            JSON.parse(sectionData?.contentDetails?.raw),
            optionsHeaderMainStyle
          )}
      </Styled.Foot>
    </Styled.FootContainer>
  );
}
