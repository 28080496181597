import styled from 'styled-components';

export const FootContainer = styled.div`
  background: #ededed;
`;

export const TitleDesktopImage = styled.img`
  max-width: 540px;
  height: auto;
  @media (max-width: 991px) {
    display: none !important;
  }
`;

export const Foot = styled.div`
  width: 70%;
  margin: 0 auto;
  padding: 30px;
  text-align: center;
  font-size: 14px;
  line-height: 130%;
  color: #9a9a9a;
`;
